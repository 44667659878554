import { useEffect } from 'react';
import Zendesk from 'components/common/Zendesk';
import Metrics from 'services/metrics/track-event';

const { show, hide } = Zendesk;

export default () =>
  useEffect(() => {
    /*
      Documentation:
      https://developer.zendesk.com/api-reference/widget-messaging/web/core/
      */
    // This script adds Zendesk to global space
    /* eslint-disable */
    window.zEmbed ||
      (function (e, t) {
        let n,
          o,
          d,
          i,
          s,
          a = [],
          r = document.createElement('iframe');
        (window.zEmbed = function () {
          a.push(arguments);
        }),
          (window.zE = window.zE || window.zEmbed),
          (r.src = 'javascript:false'),
          (r.title = ''),
          (r.role = 'presentation'),
          ((r.frameElement || r).style.cssText = 'display: none'),
          (d = document.getElementsByTagName('script')),
          (d = d[d.length - 1]),
          d.parentNode.insertBefore(r, d),
          (i = r.contentWindow),
          (s = i.document);
        try {
          o = s;
        } catch (e) {
          (n = document.domain), (r.src = `javascript:var d=document.open();d.domain="${n}";void(0);`), (o = s);
        }
        (o.open()._l = function () {
          const o = this.createElement('script');
          n && (this.domain = n),
            (o.id = 'js-iframe-async'),
            (o.src = e),
            (this.t = +new Date()),
            (this.zendeskHost = t),
            (this.zEQueue = a),
            this.body.appendChild(o);
        }),
          o.write('<body onload="document._l();">'),
          o.close();
      })('https://assets.zendesk.com/embeddable_framework/main.js', `blueapron.zendesk.com`);

    zE(() => {
      zE('messenger', 'hide'); 
    });
    /* eslint-enable */
  }, []);

export const useOpenChatWidgetEffect = (isEnabled = true, route) =>
  useEffect(() => {
    if (isEnabled && window.zE && route) {
      window.zE('messenger:on', 'open', function () {
        Metrics.trackEvent({
          action: 'Chat Widget Clicked',
          category: 'Chat',
          attributes: {
            page_name: route
          }
        });
      });
    }
  }, [isEnabled, route]);

export const useShowZendeskChatEffect = (isEnabled = true) =>
  useEffect(() => {
    if (isEnabled) {
      show();
    }
  }, [isEnabled]);

export const useHideZendeskChatEffect = (isEnabled = true) =>
  useEffect(() => {
    if (isEnabled) {
      hide();
    }
  }, [isEnabled]);
