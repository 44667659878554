// import fetchCopyEffect from './fetchCopy';
// import fetchUserEffect from './fetchUser';
// import fetchBasket from './fetchBasket';
// import fetchCoupons from './fetchCoupons';
// import initGTMEffect from './gtm';
import initZendeskEffect, {
  useShowZendeskChatEffect,
  useHideZendeskChatEffect,
  useOpenChatWidgetEffect
} from './zendesk';
// import initRollbarEffect, { setRollbarUserEffect } from './setRollbar';
// import navigatePartialUserEffect from './navigatePartialUser';
// import navigateCancelledUserEffect from './navigateCancelledUser';
import useActivateOptimizelyPageEffect from './optimizely';
// import redirectHashEffect from './redirectHashEffect';
// import trackCheckoutEventsEffect from './trackCheckoutEvents';
// import horizontalTabScrollEffect from './horizontalTabScrollEffect';
// import alertBeforeUnloadEffect from './alertBeforeUnload';
import useFlashMessageEffect from './useFlashMessageEffect';

export {
  // fetchCopyEffect,
  // fetchUserEffect,
  // fetchBasket,
  // fetchCoupons,
  initZendeskEffect,
  useShowZendeskChatEffect,
  useHideZendeskChatEffect,
  useOpenChatWidgetEffect,
  // initRollbarEffect,
  // setRollbarUserEffect,
  // navigatePartialUserEffect,
  // navigateCancelledUserEffect,
  useActivateOptimizelyPageEffect,
  // redirectHashEffect,
  // trackCheckoutEventsEffect,
  // alertBeforeUnloadEffect,
  // horizontalTabScrollEffect
  useFlashMessageEffect
};
